<script setup>
import Img from '@/Components/Img.vue';
import JobOfferCard from '@/Components/JobOffers/JobOfferCard.vue';
import PublicPaginator from '@/Components/PublicPaginator.vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

const route = inject('route');
import { useDebounceFn } from '@vueuse/shared';
import Input from '@/Components/Input/Input.vue';
import { useI18n } from 'vue-i18n';

// Componnets

// Partials
const props = defineProps({
    query: Object,
    jobOffers: Object,
    totalJobOffers: Number,
});

let filters = ref(false);

const form = useForm({
    search: props.query.search ?? '',
    employment: props.query.employment ?? [],
    education: props.query.education ?? [],
});

const { t } = useI18n();

const employmentType = { employed: t('Employed'), temp_worker: t('Temp Worker') };
const educations = {
    secondary_school: t('Secondary school'),
    secondary_vocational_education: t('Secondary vocational education'),
    higher_vocational_education: t('Higher vocational education'),
    academic_education: t('Academic education'),
};

const getData = () => {
    form.get(route('public.job_offers.index'), {
        preserveState: true,
        preserveScroll: true,
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);
</script>

<template>
    <PublicLayout title='FLEXABLE | Job offers'>
        <div class='py-10 md:min-h-10 bg-gradient-to-r from-pink to-orange'>
            <div class='container'>
                <h1 class='max-w-260 text-xl font-medium uppercase md:text-3xl xl:text-7xl h-9 sm:h-auto mb-6'>
                    <span>Alle</span>
                    <br><span
                    class='font-black'>vacatures </span>
                </h1>
                <div class='flex items-end justify-between gap-2'>
                    <div class='relative mt-2'>
                        <div>
                            <label class='block text-sm font-bold text-white' for='search-job-offer'>
                                {{ $t('Search by position, company or location') }}
                            </label>
                            <div class='relative mt-2 rounded-md shadow-sm'>
                                <input
                                    id='search-job-offer'
                                    v-model='form.search'
                                    class='md:min-w-130 form-input block w-full rounded-md border-0 py-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink sm:text-sm'
                                    name='search-job-offer'
                                    placeholder=''
                                    type='text'
                                    @input='debouncedGetData'
                                />
                                <div class='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                                    <MagnifyingGlassIcon aria-hidden='true' class='h-5 w-5 text-gray-400' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class='flex items-center gap-2 py-1' @click='filters = !filters'>
                        <span class='hidden sm:block'>
                            {{ filters ? $t('Close filters') : $t('Open filters') }}
                        </span>
                        <img alt='' class='w-8' src='@/../img/icon/filter.svg' />
                    </button>
                </div>
            </div>
        </div>

        <div v-if='filters' class='py-10 md:text-left bg-blue-medium'>
            <div class='container relative'>
                <div class='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <div>
                        <h2 class='font-bold uppercase'>
                            {{ $t('Employment') }}
                        </h2>

                        <fieldset class='mt-5'>
                            <div class='space-y-2'>
                                <div
                                    v-for='(employment, key, index) in employmentType'
                                    class='relative flex items-start'
                                >
                                    <div class='flex h-6 items-center'>
                                        <input
                                            :id="'employment' + index"
                                            v-model='form.employment'
                                            :value='key'
                                            aria-describedby='comments-description'
                                            class='form-checkbox h-4 w-4 rounded border-gray-300 text-pink focus:ring-pink'
                                            name='comments'
                                            type='checkbox'
                                            @change='getData'
                                        />
                                    </div>
                                    <div class='ml-3 text-sm'>
                                        <label :for="'employment' + index" class='font-medium text-white'>{{
                                                employment
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div>
                        <h2 class='font-bold uppercase'>
                            {{ $t('Education') }}
                        </h2>
                        <fieldset class='mt-5'>
                            <div class='space-y-2'>
                                <div v-for='(education, key, index) in educations' class='relative flex items-start'>
                                    <div class='flex h-6 items-center'>
                                        <input
                                            :id="'education' + index"
                                            v-model='form.education'
                                            :value='key'
                                            aria-describedby='comments-description'
                                            class='form-checkbox h-4 w-4 rounded border-gray-300 text-pink focus:ring-pink'
                                            name='comments'
                                            type='checkbox'
                                            @change='getData'
                                        />
                                    </div>
                                    <div class='ml-3 text-sm'>
                                        <label :for="'education' + index" class='font-medium text-white'>{{
                                                education
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>

        <div class='py-20 md:text-left bg-white'>
            <div class='container relative'>
                <h2 class='mb-15 text-4xl font-medium uppercase md:text-5xl md:text-center text-blue'>
                    {{ totalJobOffers }} <span class='font-black'>{{ $t('Job offers') }} </span>
                </h2>

                <div v-if='jobOffers.data.length' class='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <JobOfferCard v-for='jobOffer in jobOffers.data' :key='jobOffer.id' :jobOffer='jobOffer'>
                    </JobOfferCard>
                </div>

                <div v-else class='my-20'>
                    <div class='text-5xl font-bold text-blue'>
                        <span> {{ $t('No job offers found') }} </span>
                    </div>
                </div>

                <PublicPaginator :data='jobOffers'></PublicPaginator>
            </div>
        </div>
    </PublicLayout>
</template>
